<template>
  <div id="reclaim-requests">
    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" title="Registrations">
      <template v-slot:right-end>
        <div class="vx-row">
        </div>
      </template>
    </breadcrumb-base>


    <!-- Error List State-->
    <transition name="fade">
      <div class="py-6 flex w-full bg-img" v-if="errorFetching">
        <div
          class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
          <img src="@/assets/images/pages/svg_error.svg" :alt="$t('NoData')"
               class="mx-auto mb-4 max-w-full">
          <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('ErrorData') }}</h1>
        </div>
      </div>
    </transition>

    <transition name="fade" v-if="!errorFetching">

      <div class="w-full">
        <!-- Empty List State -->

        <div class="flex flex-row w-full mx-2 space-x-8 w-1/" v-if="registrationsRaw">
          <div
            class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base">

            <reclaim-card
              :text="registrationsRaw.new_registrations"
              :title="$t('New')"
              color="white"
              text-color="white"
              bg-color="warning"
              @tap="fetchNewList('new')"
              type="area"/>
          </div>
          <div
            class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base">
            <reclaim-card
              :text="registrationsRaw.accepted_registrations"
              :title="$t('Accepted')"
              color="white"
              text-color="white"
              bg-color="success"
              @tap="fetchNewList('accepted')"
              type="area"/>
          </div>
          <div
            class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base">
            <reclaim-card
              :text="registrationsRaw.rejected_registrations"
              :title="$t('Rejected')"
              color="white"
              text-color="white"
              bg-color="danger"
              @tap="fetchNewList('rejected')"
              type="area"/>
          </div>
          <!--              <div-->
          <!--                   class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/5 xl:w-1/5 mb-base">-->
          <!--                <reclaim-card-->
          <!--                  :text="registrationRequestsRaw.pin_status.credit_issued"-->
          <!--                  :title="$t('IssueCreditNote')"-->
          <!--                  color="white"-->
          <!--                  text-color="white"-->
          <!--                  bg-color="primary"-->
          <!--                  @tap="fetchNewList('issue-credit-note')"-->
          <!--                  type="area"/>-->
          <!--              </div>-->
          <!--              <div-->
          <!--                class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/5 xl:w-1/5 mb-base">-->
          <!--                <reclaim-card-->
          <!--                  :text="registrationRequestsRaw.pin_status.rejected"-->
          <!--                  :title="$t('Rejected')"-->
          <!--                  color="black"-->
          <!--                  text-color="black"-->
          <!--                  bg-color="white"-->
          <!--                  @tap="fetchNewList('reject')"-->
          <!--                  type="area"/>-->
          <!--              </div>-->
        </div>

        <div class="w-full vx-row mx-2 pt-5">
          <div class="w-full vs-col" v-if="registrations.length === 0">
            <transition name="fade">
              <div class="h-screen flex w-full bg-img" v-if="!errorFetching">
                <div
                  class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
<!--                  <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
<!--                       class="mx-auto mb-4 max-w-full">-->
                  <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('NoData') }}</h1>
                </div>
              </div>
            </transition>
          </div>
          <div class="w-full vs-col" v-if="registrations.length >= 0">
            <vs-table-modified v-show="registrations.length > 0 && !errorFetching" ref="table"
                               :max-items="itemsPerPage"
                               :data="registrations">
              <template slot="thead">
                <vs-th>{{ $t('Date') }}</vs-th>
                <vs-th>{{ $t('Name') }}</vs-th>
                <vs-th>{{ $t('Username') }}</vs-th>
                <vs-th>{{ $t('Company') }}</vs-th>
                <vs-th>{{ $t('Email') }}</vs-th>
                <vs-th>{{ $t('Mobile') }}</vs-th>
                <vs-th>{{ $t('Address') }}</vs-th>
                <vs-th>{{ $t('City') }} / {{ $t('PostalCode') }}</vs-th>
                <vs-th>{{ $t('STNR') }}</vs-th>
                <vs-th>{{ $t('USTLDNR') }}</vs-th>
                <vs-th>{{ $t('ShopType') }}</vs-th>
                <vs-th>{{ $t('IP') }}</vs-th>
                <vs-th>{{ $t('Status') }}</vs-th>
                <vs-th>{{ $t('UpdatedBy') }}</vs-th>
                <vs-th>{{ $t('Actions') }}</vs-th>
              </template>

              <template slot-scope="{data}">
                <tbody>
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td>
                    <p
                      style="max-width: 100px; word-break: normal !important;"
                      class="font-medium">{{ tr.status_updated_at  | dateFormat }}</p>
                  </vs-td>
                  <vs-td>
                    <p
                      style="max-width: 100px; word-break: normal !important;"
                      class="font-medium">{{ tr.first_name }} {{ tr.last_name }}</p>
                  </vs-td>
                  <vs-td>
                    <p
                      class="font-medium">{{ tr.username }}</p>
                  </vs-td>
                  <vs-td>
                    <p
                      class="font-medium">{{ tr.company }}</p>
                  </vs-td>
                  <vs-td>
                    <p
                      style="max-width: 100px; word-break: break-all !important;"
                      class="font-medium">{{ tr.email }}</p>
                  </vs-td>
                  <vs-td>
                    <div class="flex flex-col">
                      <p
                        style="max-width: 100px; word-break: break-all !important;"
                        class="font-medium">{{ tr.mobile }}</p>
                      <p v-if="tr.mobile_1"
                         style="max-width: 100px; word-break: break-all !important;"
                         class="font-medium">{{ tr.mobile_1 }}</p>
                    </div>
                  </vs-td>
                  <vs-td>
                    <p
                      style="max-width: 200px; word-break: break-word !important;"
                      class="font-medium">{{ tr.house_no }}, {{ tr.address }}</p>
                  </vs-td>
                  <vs-td>
                    <p
                      style="max-width: 100px; word-break: normal !important;"
                      class="font-medium">{{ tr.city }}, {{ tr.cipostalty }}</p>
                  </vs-td>
                  <vs-td>
                    <p
                      class="font-medium">{{ tr.stnr }}</p>
                  </vs-td>
                  <vs-td>
                    <p
                      class="font-medium">{{ tr.ustldnr }}</p>
                  </vs-td>
                  <vs-td>
                    <p
                      class="font-medium">{{ tr.shop_type }}</p>
                  </vs-td>
                  <vs-td>
                    <p
                      class="font-medium">{{ tr.ip_address }}</p>
                  </vs-td>
                  <vs-td>
                    <div class="flex items-center justify-center">
                      <vs-chip :color="getStatusColor(tr.status)" class="user-status">{{
                          getStatusText(tr.status) | title
                        }}
                      </vs-chip>
                    </div>
                  </vs-td>
                  <vs-td>
                    <p
                      class="font-medium">{{ tr.updated_by }}</p>
                  </vs-td>
                  <vs-td>
                    <div class="flex flex-col items-center justify-center text-center" v-if="tr.status === 0">
                      <div class="flex items-center">
                        <a
                          @click="updateRegistration(tr, 1)"
                          class="px-2 py-1 bg-success rounded-lg text-white hover:text-white">Accept</a>
                      </div>
                      <div class="flex items-center mt-1">
                        <a
                          @click="updateRegistration(tr, 2)"
                          class="px-2 py-1 bg-danger rounded-lg text-white hover:text-white">Reject</a>
                      </div>
                    </div>
                    <div class="flex flex-col items-center justify-center text-center" v-if="tr.status === 2">
                      <div class="flex items-center">
                        <a
                          @click="undoReject(tr)"
                          class="px-2 py-1 bg-primary rounded-lg text-white hover:text-white">Undo</a>
                      </div>
                    </div>
                  </vs-td>
                </vs-tr>
                </tbody>
              </template>
              <template slot="footer">
              </template>
              <template slot="footer-left">
                <div class="flex">
                  <vs-new-pagination :total="vTotalPages" v-model="cPage" v-on:change="listPageChanged">
                  </vs-new-pagination>
                </div>
              </template>
            </vs-table-modified>
          </div>
        </div>
      </div>
    </transition>

  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import moment from 'moment'
import VxModal from "@/layouts/components/custom/VxModal";
import DynamicCustomersDropDown from "@/views/pages/superadmin/customer/DynamicCustomersDropDown";
import BalanceCard from "@/views/components/BalanceCard";
import ReclaimCard from "@/views/components/ReclaimCard";

export default {
  name: 'ViewCustomerRegistrations',
  components: {ReclaimCard, BalanceCard, DynamicCustomersDropDown, Datepicker, VxModal},
  data() {
    return {
      cPage: 1,
      statusFilter: 'new',
      itemsPerPage: 20,
      errorFetching: false,
      breadcrumb: [
        {title: 'Home', i18n: 'Home', url: '/'},
        {title: 'Registrations', i18n: 'Registrations', active: true},
      ],
    }
  },
  computed: {
    disabledDates() {
      if (!this.startDate) {
        return {
          from: new Date(),
        }
      }
      return {
        to: this.startDate,
        from: new Date(),
      }
    },
    registrations() {
      return this.$store.state.customerRegistration.registrations
    },
    registrationsRaw() {
      return this.$store.state.customerRegistration.registrationsRaw
    },
    vTotalPages() {
      return this.$store.state.reclamation.vTotalPages
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
  },
  methods: {
    fetchNewList(status) {
      this.statusFilter = status
      this.fetchRegistrations()
    },
    getStatus(status) {
      if (status === 0) return 'new'
      if (status === 1) return 'accepted'
      if (status === 2) return 'rejected'
      return ''
    },
    getStatusId(status) {
      if (status === 'new') return 0
      if (status === 'accepted') return 1
      if (status === 'rejected') return 2
      return ''
    },
    getStatusText(status) {
      if (status === 0) return this.$t('New')
      if (status === 1) return this.$t('Accepted')
      if (status === 2) return this.$t('Rejected')
      return ''
    },
    getStatusColor(status) {
      if (status === 0) return 'warning'
      if (status === 1) return 'success'
      if (status === 2) return 'danger'
      return ''
    },
    listPageChanged(item) {
      this.fetchReclaimRequests(this.cPage - 1)
    },
    clearFilterData() {
      this.statusFilter = null
      this.fetchReclaimRequests()
    },
    getFilters() {
      let filters = {}
      if (this.statusFilter) {
        filters['status'] = this.getStatusId(this.statusFilter)
      }
      return filters
    },
    updateRegistration(data, status) {
      const payload = {
        'registration_id': data.id,
        'status': status,
      }
      this.$vs.loading()
      this.$store.dispatch('customerRegistration/updateRegistration', {payload})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          this.$vs.notify({
            position: 'top-center',
            title: 'Success',
            text: data.message,
            iconPack: 'feather',
            icon: 'icon-check-circle',
            color: 'success',
          })
          this.fetchRegistrations(this.cPage - 1)
        })
        .catch((err) => {
          console.log(err)
          this.$vs.loading.close()
          this.errorFetching = true
          let msg = ''
          try {
            msg = err.response.data.message
          } catch (e) {
            msg = err.message
          }
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    undoReject(data) {
      const payload = {
        'registration_id': data.id,
        'undo': 1,
      }
      this.$vs.loading()
      this.$store.dispatch('customerRegistration/updateRegistration', {payload})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          this.$vs.notify({
            position: 'top-center',
            title: 'Success',
            text: data.message,
            iconPack: 'feather',
            icon: 'icon-check-circle',
            color: 'success',
          })
          this.fetchRegistrations(this.cPage - 1)
        })
        .catch((err) => {
          console.log(err)
          this.$vs.loading.close()
          this.errorFetching = true
          let msg = ''
          try {
            msg = err.response.data.message
          } catch (e) {
            msg = err.message
          }
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    fetchRegistrations(page = '0') {
      const payload = {
        'page': page,
        ...this.getFilters()
      }
      this.$vs.loading()
      this.$store.dispatch('customerRegistration/fetchRegistrations', {payload})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((err) => {
          console.log(err)
          this.$vs.loading.close()
          this.errorFetching = true
          let msg = ''
          try {
            msg = err.response.data.message
          } catch (e) {
            msg = err.message
          }
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
  mounted() {
    this.fetchRegistrations()
  },
}
</script>

<style scoped lang="scss">
#reclaim-requests {

  .subtitle {
    font-size: 10px;
    color: #9c9c9c;
  }

  .vs-table--td {
    padding: 1px;
  }
}
</style>
